import React, { FC } from 'react';

import { RouteComponent, useRouter } from 'react-resource-router';

import { StatsigFeatureKeys } from '@atlassian/bitbucket-features';

import HistoryWatcher from 'src/components/performance-metrics/history-watcher';
import { useStatsigGate } from 'src/contexts/feature-provider';
import { useFlag } from 'src/hooks/flag';
import { RouteContext, ROUTE_NAME } from 'src/router/routes/types';
import {
  getWebinarFlagDismissed,
  WEBINAR_FLAG_ID,
} from 'src/sections/global/components/flags/webinar-ad';

/* eslint @typescript-eslint/ban-types: "warn" */
export const UniversalRoutes: FC = () => {
  const [routerState] = useRouter();
  const { route } = routerState as RouteContext;

  // TODO: Remove in mid-December
  const webinarFlag = useStatsigGate(
    StatsigFeatureKeys.webinarFlagEnabled
  ) as boolean;
  const { showFlagComponent } = useFlag();
  if (!getWebinarFlagDismissed() && webinarFlag) {
    showFlagComponent(WEBINAR_FLAG_ID);
  }

  if (
    // The root route "/" is a redirect, so we don't want to render any additional components yet
    route.name === ROUTE_NAME.ROOT
  ) {
    return <RouteComponent />;
  }

  const Wrapper = route.layout;

  return (
    <>
      <HistoryWatcher />
      {Wrapper ? (
        <Wrapper>
          <RouteComponent />
        </Wrapper>
      ) : (
        <RouteComponent />
      )}
    </>
  );
};
