export const urls = {
  setUpCustomMergeChecks:
    'https://support.atlassian.com/bitbucket-cloud/docs/set-up-and-use-custom-merge-checks/',
  branchRestrictions:
    'https://support.atlassian.com/bitbucket-cloud/docs/configure-a-projects-branch-restrictions/',
  exampleApps:
    'https://developer.atlassian.com/platform/forge/example-apps-bitbucket/',
  tutorial:
    'https://developer.atlassian.com/platform/forge/build-a-pull-request-title-validator-with-custom-merge-checks/',
};
