// TODO: Remove in mid-December
import React from 'react';

import { defineMessages, FormattedMessage } from 'react-intl';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagProps } from 'src/redux/flags/types';
import urls from 'src/urls';
import store from 'src/utils/store';

export const WEBINAR_FLAG_ID = 'webinar-flag' as const;

export const getWebinarFlagDismissed = (): boolean => {
  return store.get(WEBINAR_FLAG_ID);
};

const messages = defineMessages({
  title: {
    id: 'frontbucket.flags.webinarFlag.title',
    description: 'Text for title of flag',
    defaultMessage: 'Supercharge development with Bitbucket & Atlassian Cloud',
  },
  description: {
    id: 'frontbucket.flags.webinarFlag.description',
    description: 'Text for description of flag',
    defaultMessage: `Join this webinar and learn how to leverage AI, automations, & more to simplify your workflow`,
  },
  linkText: {
    id: 'frontbucket.flags.webinarFlag.linkText',
    description: 'Text for sign up link',
    defaultMessage: 'Sign up',
  },
});

const WebinarFlag = ({
  id,
  handleDismissed,
  ...flagProps
}: ComponentFlagProps) => {
  const intl = useIntl();
  const dismiss = () => {
    store.set(WEBINAR_FLAG_ID, true);
    handleDismissed(id);
  };

  const actions = [
    {
      content: intl.formatMessage(messages.linkText),
      onClick: () => dismiss(),
      href: urls.external.webinarSignUp,
      target: '_blank',
    },
  ];

  return (
    <Flag
      {...flagProps}
      actions={actions}
      id={id}
      title={intl.formatMessage(messages.title)}
      description={<FormattedMessage {...messages.description} />}
      icon={
        <InfoIcon
          primaryColor={token('color.icon.information', colors.B300)}
          label=""
        />
      }
      onDismissed={() => dismiss()}
    />
  );
};

export default WebinarFlag;
